import { usePlans } from '@stores/plans';
import { useSubscription } from '@stores/subscription';
import { useEffect, useMemo } from 'react';
import './style.scss';
import PlanCard from '../PlanCard/PlanCard';
import log from '@utils/analytics';
import BigPlanCard from '../BigPlanCard/BigPlanCard';
import { PlanCode } from '../../../../config/plans/types';

const UpgradeSection = (): JSX.Element => {
  const plans = usePlans((state) => state.plans);
  const subscription = useSubscription((state) => state.subscription);
  const shouldShowBigPlanCard = useSubscription((state) => state.shouldShowBigPlanCard);

  const plansToShowBasedOnActiveSub = useMemo(() => {
    // The 'Switch' strategy only calls for the Annual option for active Monthly subscribers right now (MR-1170)
    if (!subscription) return;
    if (!plans.length) return;

    const { currency } = subscription.plan;
    const upgradePlans = plans.filter((plan) => plan.config?.planCode === PlanCode.UPGRADE);

    const annualPlan = upgradePlans.find(
      (plan) => plan.interval === 'yearly' && !plan.metadata?.adFree && plan.currency === currency
    );

    // const annualAdFreePlan = upgradePlans.find(
    //   (plan) => plan.interval === 'yearly' && plan.metadata?.adFree && plan.currency === currency
    // );

    if (!annualPlan) throw new Error('Cannot find Annual plan from state');
    // if (!annualAdFreePlan) throw new Error('Cannot find Annual Ad-free plan from state');

    const annualPlanCard = shouldShowBigPlanCard ? (
      <BigPlanCard plan={annualPlan} />
    ) : (
      <PlanCard plan={annualPlan} />
    );

    if (subscription.cadence === 'monthly') {
      return annualPlanCard;
    }

    // if (subscription.cadence === 'Annual') {
    //   return (
    //     <>
    //       <PlanCard plan={annualAdFreePlan} />
    //     </>
    //   );
    // }
  }, [plans, subscription, shouldShowBigPlanCard]);

  useEffect(() => {
    log.track({
      eventCategory: 'membership_my_insider_nav',
      eventAction: 'my_insider_subscription_forseti',
      eventLabel: 'my_subscriptions_loads',
      element_name: 'my_insider_subscription',
      action: 'my_subscriptions_loads',
      price_amount: '',
      account_type: 'individual_subscription',
      current_term: subscription?.plan.id,
      intended_term: '',
      click_text: '',
      click_url: '',
    });
  }, [subscription?.plan.id]);

  return (
    <section data-testid='subs-upgrade-section' className='upgrade__section'>
      <h2 className='upgrade__title'>Switch and save</h2>
      {plansToShowBasedOnActiveSub}
    </section>
  );
};

export default UpgradeSection;

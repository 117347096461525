import styles from './style.module.scss';
import { OperationActionMessage } from '../../operationActionMessage';
import { usePurchase } from '@stores/purchase';
import { trackEvent } from '../../preCancelationFlow/analytics';
import { useEffect } from 'react';
import { formatPrice } from '@utils/price';
import { Helmet } from 'react-helmet-async';
import { useSubscription } from '@stores/subscription';

const PurchaseThankYou: () => JSX.Element = () => {
  const context = usePurchase((state) => state.context);
  const selectedPlan = usePurchase((state) => state.selectedPlan);
  const subscription = useSubscription((state) => state.subscription);

  useEffect(() => {
    if (context === 'precancelation') {
      if (!selectedPlan) return;
      const offerPrice = formatPrice(selectedPlan.prices[0].amount);

      trackEvent({
        account_type: 'individual_subscription',
        action: 'transaction_successful',
        element_name: 'cancellation',
        event: 'checkout_flow',
        current_term: subscription?.plan.id,
        intended_term: selectedPlan.id,
        dynamic_pricing: 'smart_offers',
        login_type: 'bi_value_unassigned',
        subscription_experience: 'cancellation_flow',
        payment_type: 'credit_card',
        price_amount: `${offerPrice}${selectedPlan?.currency}`,
      });

      trackEvent({
        account_type: 'individual_subscription',
        action: 'transaction_successful',
        element_name: 'save_offer_screen',
        event: 'cancellation_flow',
        current_term: subscription?.plan.id,
        intended_term: selectedPlan.id,
      });
    }

    if (context === 'upgrade') {
      //TBA
    }
  }, [context, subscription, selectedPlan]);

  const handleBackToAccount = () => {
    // nothing here
  };

  return (
    <>
      <Helmet title='My Insider | Thank You' />
      <OperationActionMessage
        classes={{
          container: styles.container,
          contentWrapper: styles['content-wrapper'],
          title: styles.title,
        }}
        showThumbsUp
        onBackToAccount={handleBackToAccount}
        title='Thank you for keeping your subscription!'
        content={
          <>
            With your subscription you can have full access to our content, pitch decks,
            subscriber-exclusive events, and many more.
          </>
        }
      />
    </>
  );
};

export default PurchaseThankYou;

import { useToggle } from '@utils/useToggle';
import { SectionToggle } from '../sectionToggle';
import { PreCancelationFlow } from './content';

export const PreCancelationFlowLauncher: () => JSX.Element = () => {
  const [showCancelationFlow, toggleShowCancelationFlow] = useToggle(false);

  return (
    <>
      <SectionToggle title='Cancel your subscription' onExpand={toggleShowCancelationFlow} />
      {showCancelationFlow && <PreCancelationFlow onClose={toggleShowCancelationFlow} />}
    </>
  );
};

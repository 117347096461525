import { useState, useEffect } from 'react';
import { Routes, Route, Outlet, useSearchParams } from 'react-router-dom';
import { StatsigProvider } from 'statsig-react';
import getConfig from '@utils/getConfig';
import SavedArticles from './screens/saved-articles/SavedArticles';
import CorpManagement from './screens/account/corp-mgmt';
import Subscription from './screens/account/subscription';
import ErrorPage from './screens/error-page';
import Checkout from './screens/checkout';
import { useUser } from './stores/user';
import { useEnterprise } from './stores/enterprise';
import CorpAdminRoute from '@components/routes/CorpAdminRoute';

import { isJwt } from '@utils/validation';
import { RouteGuard } from '@components/routes';
import PurchaseOrderSummary from './screens/account/subscription/purchase/PurchaseOrderSummary/PurchaseOrderSummary';
import PurchaseThankYou from './screens/account/subscription/purchase/PurchaseThankYou/thank-you';
import { SpinnerFullScreen } from '@components/spinner-fullscreen';
import CancelationThankYou from './screens/account/subscription/preCancelationFlow/thank-you';

import Onboarding from './screens/corporate/onboarding';
import RouteWithSidebar from '@components/routes/RouteWithSidebar';
import Interest from './screens/account/interest';

import { storeComingFromLink } from '@utils/comingFromLink';
import { useStatsigUser } from '@statsig/user';
import log from '@utils/analytics';
import TagManager from 'react-gtm-module';

type LoadingCrititalStatus = 'idle' | 'loading' | 'done';

const AppRouter = (): JSX.Element => {
  const refreshUser = useUser((state) => state.refresh);
  const loginWithToken = useUser((state) => state.loginWithToken);
  const updateEnterprise = useEnterprise((state) => state.fetch);
  const validateEnterprise = useEnterprise((state) => state.validate);
  const isEnterpriseUser = useUser((state) => state.isEnterpriseUser);
  const statsigUser = useStatsigUser();
  const { statsig: statsigClientKey } = getConfig();

  const tryValidateEnterprise = async () => {
    if (isEnterpriseUser()) {
      await updateEnterprise();
      validateEnterprise();
    }
  };

  // *** Critical Data ***
  // TODO: move this logic outside the Router
  const [searchParams, setSearchParams] = useSearchParams();
  const [loadingCritical, setLoadingCritical] = useState<LoadingCrititalStatus>('idle');
  useEffect(() => {
    const token = searchParams.get('t');
    if (token && isJwt(token)) {
      setLoadingCritical('loading');
      loginWithToken(token)
        .then(() => {
          storeComingFromLink(searchParams);
          searchParams.delete('t');
          setSearchParams(searchParams, { replace: true });
        })
        .catch((error) => {
          if (process.env.NODE_ENV === 'development') {
            console.warn(error);
          }
        })
        .finally(async () => {
          await tryValidateEnterprise();
          setLoadingCritical('done');
        });
      return;
    } else {
      refreshUser().finally(async () => {
        await tryValidateEnterprise();

        setLoadingCritical('done');
      });
    }
  }, []);

  const isTokenValid = useUser((state) => state.isTokenValid);

  useEffect(() => {
    if (isTokenValid() && loadingCritical === 'done') {
      log.initAndPushIds();
    }
  }, [isTokenValid, loadingCritical]);

  /*
    It's not possible to check if user is null and return 403 because
    /checkout returns blockedPage or 403 depending on if is enterprise user if user is null
  */
  if (loadingCritical !== 'done') {
    return <SpinnerFullScreen colorScheme='variant' />;
  }
  return (
    <StatsigProvider
      sdkKey={statsigClientKey}
      user={statsigUser}
      waitForInitialization={true}
      options={{
        environment: {
          tier: process.env.NODE_ENV,
        },
      }}>
      <Routes>
        {/* Add new routes here; non-matches default to 404 */}

        <Route path='/' element={<CorpAdminRoute />}>
          <Route path='/account/corporate-management' element={<CorpManagement />} />
        </Route>

        <Route
          path='/'
          element={
            <RouteGuard canAccess={() => isTokenValid()} restricted={<ErrorPage errorCode={403} />}>
              <Outlet />
            </RouteGuard>
          }>
          <Route path='/' element={<RouteWithSidebar />}>
            <Route path='/saved-articles' element={<SavedArticles />} />
            <Route path='/account/interest' element={<Interest />} />
            <Route path='/account/subscription' element={<Subscription />} />
            <Route path='/account/checkout/:planId' element={<PurchaseOrderSummary />} />
            <Route path='/account/checkout/thank-you' element={<PurchaseThankYou />} />
            <Route path='/account/cancel/thank-you' element={<CancelationThankYou />} />
          </Route>
        </Route>

        <Route
          path='/checkout'
          element={
            <RouteGuard canAccess={() => isTokenValid()} restricted={<ErrorPage errorCode={403} />}>
              <Checkout />
            </RouteGuard>
          }
        />
        <Route
          path='onboarding/*'
          element={
            <RouteGuard canAccess={() => isTokenValid()} restricted={<ErrorPage errorCode={403} />}>
              <Onboarding />
            </RouteGuard>
          }
        />
        <Route path='/unauthorized' element={<ErrorPage errorCode={403} />} />
        <Route path='/404' element={<ErrorPage errorCode={404} />} />
        <Route path='*' element={<ErrorPage errorCode={404} />} />
      </Routes>
    </StatsigProvider>
  );
};

export default AppRouter;
